const blockVideoScrollReveal = () => {
  ScrollReveal().reveal(".block-video h2", { delay: 400, duration: 1300, origin: "left", distance: "50px", easing: "ease-out" }); // eslint-disable-line
  ScrollReveal().reveal(".block-video .bg-container", { delay: 400, duration: 1300, origin: "right", distance: "50px", easing: "ease-out" }); // eslint-disable-line
};

const blockVideoIframe = () => {
  const iframes = document.querySelectorAll(".block-video iframe");

  if (iframes.length === 0) {
    return;
  }

  [...iframes].forEach((item) => {
    const videoId = item.dataset.videoid;
    if (item.dataset.playertype === "youtube") {
      const url = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
      let defaultImage = "hqdefault.jpg";
      const img = new Image();// eslint-disable-line
      img.src = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
      img.onload = () => {
        if (img.width > 1) {
          defaultImage = "maxresdefault.jpg";
        }
        const iframeSrcdocVideoUrl = `https://img.youtube.com/vi/${videoId}/${defaultImage}`;
        const iframeSrcdoc = `<style>*{padding:0;margin:0;overflow:hidden;}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;right:0;left:0;}#playbuttonwrapper{position:absolute;top:50%;left:50%;height:10vw;width:10vw;background-color:#656c5f;border-radius:100%;border:2px solid #465149;transform:translate(-50%,-50%);}span{display:flex;justify-content:center;align-items:center;height:10vw;width:10vw;font-size:5vw;color:#D5CCC0;transform:translateX(5%);}</style><a href='${url}'><img src='${iframeSrcdocVideoUrl}' alt='video thumbnail'><div id="playbuttonwrapper"><span>▶</span></div></a>`;
        item.srcdoc = iframeSrcdoc;// eslint-disable-line
      };
    } else if (item.dataset.playertype === "vimeo") {
      const url = `https://player.vimeo.com/video/${videoId}?autoplay=1&autopause=0&title=0&byline=0&portrait=0`;
      const iframeSrcdocVideoUrl = item.dataset.imgurl;
      const iframeSrcdoc = `<style>*{padding:0;margin:0;overflow:hidden;}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;right:0;left:0;}#playbuttonwrapper{position:absolute;top:50%;left:50%;height:10vw;width:10vw;background-color:#656c5f;border-radius:100%;border:2px solid #465149;transform:translate(-50%,-50%);}span{display:flex;justify-content:center;align-items:center;height:10vw;width:10vw;font-size:5vw;color:#D5CCC0;transform:translateX(5%);}</style><a href='${url}'><img src='${iframeSrcdocVideoUrl}' alt='video thumbnail'><div id="playbuttonwrapper"><span>▶</span></div></a>`;
      item.srcdoc = iframeSrcdoc;// eslint-disable-line
    }
  });
};

document.addEventListener("DOMContentLoaded", () => {
  blockVideoScrollReveal();
  blockVideoIframe();
});
